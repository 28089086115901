<template>
  <div class="create-admin">
    <p class="text-header">Manage Admin</p>

    <div class="header-title">Create</div>
    <div class="body" v-if="!isEdit">
      <b-row>
        <b-col cols="6">
          <InputText
            v-model="form.firstName"
            textFloat="Firstname"
            placeholder="Firstname"
            type="text"
            name="textFisrtname"
            isRequired
            :isValidate="$v.form.firstName.$error"
            :v="$v.form.firstName"
          />
        </b-col>
        <b-col cols="6">
          <InputText
            v-model="form.lastName"
            textFloat="Lastname"
            placeholder="Lastname"
            type="text"
            name="textLastname"
            isRequired
            :isValidate="$v.form.lastName.$error"
            :v="$v.form.lastName"
          />
        </b-col>
        <b-col cols="6">
          <InputText
            v-model="form.username"
            textFloat="Username"
            placeholder="Username"
            type="text"
            name="textUser"
            isRequired
            :isValidate="$v.form.username.$error"
            :v="$v.form.username"
          />
          <p class="m-0 text-error">
            {{ errUsername }}
          </p>
        </b-col>
        <b-col cols="6">
          <InputText
            v-model="form.password"
            textFloat="Password"
            placeholder="Password"
            autocomplete="new-password"
            type="password"
            name="textUserPw"
            isRequired
            :isShowPassword="true"
            :isValidate="$v.form.password.$error"
            :v="$v.form.password"
          />
          <p class="m-0 text-error">
            {{ errPasword }}
          </p>
        </b-col>
        <b-col cols="6">
          <InputSelect
            v-model="form.adminRoleId"
            :options="optionsRole"
            title="Role"
            name="Role"
            isRequired
            valueField="id"
            textField="name"
            @onDataChange="($event) => (form.adminRoleId = $event)"
            :isValidate="$v.form.adminRoleId.$error"
            :v="$v.form.adminRoleId"
          >
            <template v-slot:option-first>
              <b-form-select-option :value="null" disabled
                >-- Select Role --
              </b-form-select-option>
            </template>
          </InputSelect>
        </b-col>
        <b-col>
          <div class="tw-flex tw-h-full tw-mt-4 tw-items-center">
            <div
              :class="`${
                EnabledUser ? 'tw-text-green-500' : 'tw-text-red-500'
              } `"
            >
              {{ EnabledUser ? "Active" : "Inactive" }}
            </div>
            <b-form-checkbox
              v-model="EnabledUser"
              class="pointer tw-ml-2"
              switch
              :value="true"
              :unchecked-value="false"
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="body" v-if="formEdit">
      <b-row>
        <b-col cols="6">
          <InputText
            v-model="formEdit.firstName"
            textFloat="Firstname"
            placeholder="Firstname"
            type="text"
            name="textFisrtname"
            isRequired
            :isValidate="$v.formEdit.firstName.$error"
            :v="$v.formEdit.firstName"
          />
        </b-col>
        <b-col cols="6">
          <InputText
            v-model="formEdit.lastName"
            textFloat="Lastname"
            placeholder="Lastname"
            type="text"
            name="textLastname"
            isRequired
            :isValidate="$v.formEdit.lastName.$error"
            :v="$v.formEdit.lastName"
          />
        </b-col>
        <b-col cols="6">
          <InputText
            v-model="formEdit.userName"
            textFloat="Username"
            placeholder="Username"
            type="text"
            name="textUsername"
            isRequired
            :isValidate="$v.formEdit.userName.$error"
            :v="$v.formEdit.userName"
          />
          <p class="m-0 text-error">
            {{ errUsername }}
          </p>
        </b-col>
        <b-col cols="6">
          <InputSelect
            v-model="formEdit.adminRoleId"
            :options="optionsRole"
            title="Role"
            name="Role"
            isRequired
            valueField="id"
            textField="name"
            @onDataChange="($event) => (form.adminRoleId = $event)"
          >
            <!-- :isValidate="$v.form.adminRoleId.$error"
            :v="$v.form.adminRoleId" -->
            <template v-slot:option-first>
              <b-form-select-option :value="null" disabled
                >-- Select Role --
              </b-form-select-option>
            </template>
          </InputSelect>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <div class="tw-flex">
            <div
              :class="`${
                EnabledUser ? 'tw-text-green-500' : 'tw-text-red-500'
              } `"
            >
              {{ EnabledUser ? "Active" : "Inactive" }}
            </div>
            <b-form-checkbox
              v-model="EnabledUser"
              class="pointer tw-ml-2"
              switch
              :value="true"
              :unchecked-value="false"
            />
          </div>
        </b-col>
        <b-col cols="6">
          <p class="m-0 text w-max" @click="openModalEditPassword">
            Reset Password
          </p>
        </b-col>
      </b-row>
    </div>
    <div class="footer">
      <b-button class="btn-filter btn cancel" @click="goBackRouter">
        Cancel
      </b-button>
      <b-button
        class="btn-filter btn"
        :disabled="isLoading"
        @click="createUserAdmin"
        v-if="!isEdit"
      >
        Create
        <b-spinner
          label="Spinning"
          class="ml-1"
          small
          v-if="isLoading"
        ></b-spinner>
      </b-button>
      <b-button
        class="btn-filter btn"
        :disabled="isLoading"
        @click="updateUserAdmin"
        v-if="isEdit"
      >
        Update
        <b-spinner
          label="Spinning"
          class="ml-1"
          small
          v-if="isLoading"
        ></b-spinner>
      </b-button>
    </div>

    <ModalAlertText :textModal="textModal" :arrModal="arrStatus" />

    <ModalEditPassowrd @editSuccess="editSuccess" />
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import ModalAlertText from "@/components/modal/ModalAlertText.vue";
import ModalEditPassowrd from "./component/ModalEditPassword.vue";

export default {
  components: {
    ModalAlertText,
    ModalEditPassowrd,
  },
  data() {
    return {
      form: {
        email: null,
        displayName: "",
        firstName: "",
        lastName: "",
        username: "",
        confirmPassword: "",
        password: "",
        adminRoleId: null,
        enabled: false,
      },

      isEdit: false,

      formEdit: null,

      //   state option
      optionsRole: [],

      //   state error
      errPasword: "",
      errUsername: "",

      //   state toggle
      isLoading: false,

      // state modal
      textModal: "",
      arrStatus: [],
    };
  },
  validations() {
    const form = {
      firstName: { required },
      lastName: { required },
      username: { required, minLength: minLength(8) },
      password: { required, minLength: minLength(8) },
      adminRoleId: { required },
    };
    const formEdit = {
      firstName: { required },
      lastName: { required },
      userName: { required, minLength: minLength(8) },
      adminRoleId: { required },
    };
    return {
      form,
      formEdit,
    };
  },

  mounted() {
    if (this.$route.params.id) {
      this.getUserById();
      this.isEdit = true;
    }
    this.getOptionAdminRole();
  },
  computed: {
    EnabledUser: {
      set(value) {
        if (this.isEdit) {
          this.formEdit.enabled = value;
        } else {
          this.form.enabled = value;
        }
      },
      get() {
        return this.isEdit ? this.formEdit.enabled : this.form.enabled;
      },
    },
  },
  methods: {
    async getOptionAdminRole() {
      const getData = await this.$services.master.getOptionAdminRole(true);

      if (getData.result == 1) {
        this.optionsRole = getData.detail;
      }
    },

    async getUserById() {
      const getData = await this.$services.admin.getUserById(
        this.$route.params.id
      );

      if (getData.result == 1) {
        this.formEdit = getData.detail;
      }
    },

    async createUserAdmin() {
      this.$v.form.$touch();

      //   reset err text
      this.errPasword = "";
      this.errUsername = "";

      if (this.$v.form.$error) {
        return;
      }

      //   ! check username and password

      const regexString = /[a-zA-Z]/g;
      const regexNumber = /[0-9]/g;

      const conditionUsername = regexString.test(this.form.username);
      const conditionPassword = regexNumber.test(this.form.password);

      let checkCondition = false;

      //   ? USERNAME

      if (!conditionUsername) {
        this.errUsername = "Username ต้องมีอักษรอย่างน้อย 1 ตัว";
        checkCondition = true;
      } else if (this.form.username.includes(" ")) {
        this.errUsername = "Username ต้องไม่มีการเว้นวรรค์";
        checkCondition = true;
      }

      //  ? PASSWORD

      if (!conditionPassword) {
        this.errPasword = "Password ต้องมีตัวเลขอย่างน้อย 1 ตัว";
        checkCondition = true;
      } else if (this.form.password.includes(" ")) {
        this.errPasword = "Password ต้องไม่มีการเว้นวรรค์";
        checkCondition = true;
      }

      if (checkCondition) {
        return;
      }

      let body = {
        ...this.form,
        displayName: `${this.form.firstName} ${this.form.lastName}`,
        confirmPassword: this.form.password,
      };

      this.isLoading = true;

      const create = await this.$services.admin.createUserAdmin(body);

      if (create.result == 1) {
        this.$store.dispatch("UPDATE_TEXTMODAL", create.detail);
        this.$router.push("/admin");
      } else {
        this.openModalAlertText(create.detail.join(" "));
      }

      this.isLoading = false;
    },

    async updateUserAdmin() {
      this.$v.formEdit.$touch();

      //   reset err text
      this.errUsername = "";

      if (this.$v.formEdit.$error) {
        return;
      }

      let checkCondition = false;

      const regexString = /[a-zA-Z]/g;

      const conditionUsername = regexString.test(this.formEdit.userName);

      //   ? USERNAME

      if (!conditionUsername) {
        this.errUsername = "Username ต้องมีอักษรอย่างน้อย 1 ตัว";
        checkCondition = true;
      } else if (this.formEdit.userName.includes(" ")) {
        this.errUsername = "Username ต้องไม่มีการเว้นวรรค์";
        checkCondition = true;
      }

      if (checkCondition) {
        return;
      }

      let { firstName, lastName, userName, adminRoleId, enabled } =
        this.formEdit;

      const body = {
        firstName,
        lastName,
        userName,
        adminRoleId,
        enabled,
      };

      this.isLoading = true;

      const update = await this.$services.admin.updateUserAdmin(
        this.$route.params.id,
        body
      );

      if (update.result == 1) {
        this.$store.dispatch("UPDATE_TEXTMODAL", update.detail.message);
        this.$router.push("/admin");
      }

      this.isLoading = false;
      //  else {
      //   this.openModalAlertText(create.detail.join(" "));
      // }
    },

    editSuccess(text) {
      this.openModalAlertText(text);
    },

    openModalEditPassword() {
      this.$bvModal.show("modal-edit-password");
    },

    openModalAlertText(text) {
      this.textModal = text;
      this.$bvModal.show("modal-alert-text");
    },
    goBackRouter() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.create-admin {
  .text-header {
    color: #092d53;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }
  .header-title {
    padding: 15px;
    background: var(--secondary-color);
    color: var(--font-color);
    .title {
      color: white;
    }
  }
  .body {
    padding: 15px;
    background: white;

    .text {
      &.w-max {
        width: max-content;
        text-decoration: underline;
        color: #00f;
        cursor: pointer;
        font-size: 12px;
      }
    }
  }
  .footer {
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;

    .btn {
      min-width: 100px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>

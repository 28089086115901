<template>
  <div>
    <b-modal
      v-model="isModal"
      id="modal-edit-password"
      body-class="p-0"
      hide-header
      hide-footer
      centered
    >
      <div class="modal-edit-password">
        <InputText
          v-model="form.password"
          textFloat="New Password"
          placeholder="New Password"
          type="password"
          name="newPassword"
          autocomplete="new-password"
          isRequired
          :isShowPassword="true"
          :isValidate="$v.form.password.$error"
          :v="$v.form.password"
        />

        <p class="m-0 text-error">
          {{ errPasword }}
        </p>

        <InputText
          v-model="form.confirmPassword"
          textFloat="Confirm Password"
          placeholder="Confirm Password"
          type="password"
          name="confirmPassword"
          isRequired
          :isShowPassword="true"
          :isValidate="$v.form.confirmPassword.$error"
          :v="$v.form.confirmPassword"
        />

        <div class="footer">
          <b-button class="btn-filter" @click="closeModal">Cancel</b-button>
          <b-button
            class="btn-filter"
            :disabled="isLoading"
            @click="onChangePassword"
          >
            Save
            <b-spinner
              label="Spinning"
              class="ml-1"
              small
              v-if="isLoading"
            ></b-spinner>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      isModal: false,

      form: {
        password: "",
        confirmPassword: "",
      },

      //   state error
      errPasword: "",

      //   state loading
      isLoading: false,
    };
  },
  validations() {
    return {
      form: {
        password: { required, minLength: minLength(8) },
        confirmPassword: {
          required,
          sameAsPassword: sameAs("password"),
        },
      },
    };
  },
  watch: {
    isModal() {
      this.clearForm();
    },
  },
  methods: {
    async onChangePassword() {
      this.$v.form.$touch();

      //   clear error
      this.errPasword = "";

      if (this.$v.form.$error) {
        return;
      }

      const regexNumber = /[0-9]/g;
      const conditionPassword = regexNumber.test(this.form.password);

      let checkCondition = false;

      //  ? PASSWORD

      if (!conditionPassword) {
        this.errPasword = "Password ต้องมีตัวเลขอย่างน้อย 1 ตัว";
        checkCondition = true;
      } else if (this.form.password.includes(" ")) {
        this.errPasword = "Password ต้องไม่มีการเว้นวรรค์";
        checkCondition = true;
      }

      if (checkCondition) {
        return;
      }

      this.isLoading = true;
      const change = await this.$services.admin.resetPasswordAdmin(
        this.$route.params.id,
        this.form
      );

      this.isLoading = false;

      if (change.result == 1) {
        this.$emit("editSuccess", change.detail);
        this.closeModal();
      }
    },
    clearForm() {
      this.form = {
        password: "",
        confirmPassword: "",
      };
      this.$v.form.$reset();
    },
    closeModal() {
      this.$bvModal.hide("modal-edit-password");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-edit-password {
  padding: 20px;

  .footer {
    display: flex;
    justify-content: space-between;
  }
}

// ! OVERRIDE
.btn-filter {
  width: 100%;
  max-width: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
